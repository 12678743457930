// SiteInfo.tsx
import React from 'react';
import { Box, Typography, Link, Button, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Site } from '@irvin-group/api-data-types'; // Adjust the import path as needed
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ActionButton from './ActionButton'
import {useAPI} from "../services/APIContext";
import EventsTabContent from './EventsTabContent';
import CrawlerResultsTabContent from "./CrawlerResultsTabContent";
import SiteInfoTabContent from "./SiteInfoTabContent";
import AirtableWebhookStatusIndicator from "./statusIndicators/WebhookStatusIndicator";
import AirtableWebhookTabContent from "./AirtableWebhookTabContent";

interface SiteInfoProps {
    selectedSite: Site;
}

const SiteDetails: React.FC<SiteInfoProps> = ({ selectedSite }) => {
    const [tabIndex, setTabIndex] = React.useState("info");
    const { api } = useAPI(); // Use the API context if needed for actions

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue);
    };

    const handleEditInAirtable = () => {
        const url = `https://airtable.com/${selectedSite.airtableAppId}`;
        window.open(url, '_blank');
    };

    const handleBuild = async () => {
        await api.buildSite(selectedSite.slug);
        // Optionally refresh site details or show notification
    };

    const handleCheckDraft = async () => {
        await api.checkDraftSite(selectedSite.slug);
        // Optionally refresh site details or show notification
    };

    const handlePreviewDraft = () => {
        const url = `https://${selectedSite.slug}-draft.static-sites.irvin-group-labs.com`;
        window.open(url, '_blank');
    };

    const handlePublish = async () => {
        await api.publishSite(selectedSite.slug);
        // Optionally refresh site details or show notification
    };

    const handleCheckProd = async () => {
        await api.checkProductionSite(selectedSite.slug);
        // Optionally refresh site details or show notification
    };

    const handlePreviewPublished = () => {
        const url = `https://${selectedSite.slug}.static-sites.irvin-group-labs.com`;
        window.open(url, '_blank');
    };

    const handleProvision = async () => {
        await api.createNewSingleSiteChangeSet(selectedSite.slug);
        // Optionally refresh site details or show notification
    };



    return (
        <Box sx={{ padding: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>

                <Typography variant="h3" gutterBottom>
                    {selectedSite.slug}
                </Typography>
                <Typography variant="subtitle1">
                    URL: <Link href={`https://${selectedSite.siteUrl}`} target="_blank">{selectedSite.siteUrl}</Link>
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginBottom: 2 }}> {/* Adjust marginBottom as needed for spacing to next element */}
                    <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={handleEditInAirtable}>Edit in Airtable</Button>
                    <ActionButton variant="contained" color="secondary" onClick={handleBuild}>🔨 Build</ActionButton>
                    <ActionButton variant="contained" color="warning" onClick={handleCheckDraft}>🔎 Check draft</ActionButton>
                    <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={handlePreviewDraft}>Preview draft</Button>
                    <ActionButton variant="contained" color="info" onClick={handlePublish}>🚀 Publish</ActionButton>
                    <ActionButton variant="contained" color="warning" onClick={handleCheckProd}>🔎 Check prod</ActionButton>
                    <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={handlePreviewPublished}>Preview published</Button>
                    <ActionButton variant="contained" color="error" onClick={handleProvision}>Provision</ActionButton>
                </Box>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Site Info" value="info" />
                                <Tab label="Events" value="events" />
                                <Tab label="Draft Check" icon={selectedSite.draftCheckStatus != 'SUCCESS' ? <Typography>⚠️</Typography> : undefined} iconPosition="end" value="draft_check" />
                                <Tab label="Prod Check" icon={selectedSite.productionCheckStatus != 'SUCCESS' ? <Typography>⚠️</Typography> : undefined} iconPosition="end" value="prod_check" />
                                <Tab label="Airtable Webhook" value="airtable_webhook" />
                            </TabList>
                        </Box>
                        <TabPanel value="info">
                            <SiteInfoTabContent selectedSite={selectedSite} />
                        </TabPanel>
                        <TabPanel value="events" sx={{ padding: 0 }}>
                            <EventsTabContent selectedSite={selectedSite} />
                        </TabPanel>
                        <TabPanel value="draft_check" sx={{ padding: 0 }}>
                            <CrawlerResultsTabContent selectedSite={selectedSite} environment={'draft'}></CrawlerResultsTabContent>
                        </TabPanel>
                        <TabPanel value="prod_check" sx={{ padding: 0 }}>
                            <CrawlerResultsTabContent selectedSite={selectedSite} environment={'prod'}></CrawlerResultsTabContent>
                        </TabPanel>
                        <TabPanel value="airtable_webhook" sx={{ padding: 0 }}>
                            <AirtableWebhookTabContent airtableAppId={selectedSite.airtableAppId} slug={selectedSite.slug}></AirtableWebhookTabContent>
                        </TabPanel>
                    </TabContext>
                </Box>


        </Box>
    );
};

export default SiteDetails;
