import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useAPI} from "../../services/APIContext";
import {ListWebhooksResponse} from "@irvin-group/airtable-data-types";
import {WebhookItem} from "../../../../../src/lib/repositories/webhook.repository";

interface AirtableWebhookStatusIndicatorProps {
    airtableAppId: string;
    slug: string
}

const AirtableWebhookStatusIndicator: React.FC<AirtableWebhookStatusIndicatorProps> = ({ airtableAppId, slug }) => {
    const {api} = useAPI();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [webhookResponse, setWebhookResponse] = useState<WebhookItem | undefined>(undefined);

    const checkWebhooks = async () => {
        setIsLoading(true);
        try {
            const webhook = await api.listWebhook(slug);
            setWebhookResponse(webhook); // Assuming it returns an array, adjust based on your actual implementation
        } catch (error) {
            setWebhookResponse(undefined);
            console.error("Failed to list webhooks:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetWebhook = async () => {
        setIsLoading(true);
        try {
            await api.setWebhook(slug);
            await checkWebhooks(); // Re-check webhooks after setting
        } catch (error) {
            console.error("Failed to set webhook:", error);
            setIsLoading(false); // Ensure loading state is reset if an error occurs
        }
    };

    useEffect(() => {
        if (slug) {
            checkWebhooks();
        }
    }, [slug]);

    if (!slug) {
        return <Box component="span">slug not available</Box>;
    }

    return (
        <Box component="span" sx={{ alignItems: 'center', color: 'grey.600', fontWeight: 'normal' }}>
            {isLoading ? (
                <LoadingButton loading variant="outlined">
                    Set Webhook
                </LoadingButton>
            ) : webhookResponse ? (
                <Tooltip title={`Cursor: ${webhookResponse.cursor} Last event: ${webhookResponse.lastEventAt}`}>
                    <CheckCircleIcon color="success" />
                </Tooltip>
            ) : (
                <LoadingButton onClick={handleSetWebhook} loading={isLoading} variant="outlined">
                    Set Webhook
                </LoadingButton>
            )}
        </Box>
    );
};

export default AirtableWebhookStatusIndicator;
