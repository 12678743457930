export function formatLocalDateTime(isoString: Date | string | null | undefined) {
    if (!isoString) {
        return '';
    }

    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }

    // Format the date according to the local timezone and specified options
    const formatter = new Intl.DateTimeFormat('default', {
        year: "numeric",
        month: 'long', // "short" for abbreviated month, "numeric" for number
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true // Use false for 24-hour format
    });
    return formatter.format(date);
}