import React, { useRef, useState } from 'react';
import ClipboardJS from 'clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const buttonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
};

const iconStyles = {
    marginRight: '4px', // Adjust spacing between the icon and text
};

interface CopyButtonProps {
    text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
    const [isCopied, setIsCopied] = useState(false);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const handleCopyClick = () => {
        // Initialize clipboard.js with the reference to the button element
        const clipboard = new ClipboardJS(buttonRef.current as Element, {
            text: () => text, // Function to specify the text to copy
        });

        // Set copied state to true when copying is successful
        clipboard.on('success', () => {
            setIsCopied(true);

            // Reset the copied state after a short delay
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });

        // Dispose of the clipboard instance when the button unmounts
        return () => {
            clipboard.destroy();
        };
    };

    return (
        <button
            ref={buttonRef}
            style={buttonStyles}
            className={`copy-button ${isCopied ? 'copied' : ''}`}
            data-clipboard-text={text}
            onClick={handleCopyClick}
        >

            {isCopied ? 'Copied!' : <FontAwesomeIcon icon={faCopy} style={iconStyles} />}
        </button>
    );
};

export default CopyButton;
