import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningIcon from '@mui/icons-material/Warning';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SfnTaskStatus } from "@irvin-group/api-data-types";


interface StatusIndicatorProps {
    status: SfnTaskStatus;
}


const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
    const renderIconAndColor = () => {
        switch (status) {
            case 'running':
                return { icon: <HourglassEmptyIcon />, color: 'blue', text: 'Running' };
            case 'error':
                return { icon: <ErrorIcon />, color: 'red', text: 'Error' };
            case 'invalidation':
                return { icon: <WarningIcon />, color: 'orange', text: 'Invalidation' };
            case 'success':
                return { icon: <CheckCircleIcon />, color: 'green', text: 'Success' };
            case '':
                return { icon: <HelpOutlineIcon />, color: 'gray', text: 'Pending' };
            default:
                return { icon: <HelpOutlineIcon />, color: 'gray', text: 'Unknown' };
        }
    };

    const { icon, color, text } = renderIconAndColor();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: color }}>
            {icon}
            <Typography variant="body1">{text}</Typography>
        </Box>
    );
};

export default StatusIndicator;
