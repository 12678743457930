// EventsTabContent.tsx
import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import { useAPI } from '../services/APIContext';
import { Site } from '@irvin-group/api-data-types'; // Adjust the import path as needed
import { SfnTaskInfo } from "@irvin-group/api-data-types";
import {formatLocalDateTime} from "../utils/dateHelperFunctions";
import StatusIndicator from './statusIndicators/StatusIndicator';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface EventsTabContentProps {
    selectedSite: Site;
}

const EventsTabContent: React.FC<EventsTabContentProps> = ({ selectedSite }) => {
    const { api } = useAPI();
    const [tasks, setTasks] = useState<SfnTaskInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchTasks = async () => {
        setLoading(true);
        try {
            const tasks = await api.getSfnTasksBySlug(selectedSite.slug);
            setTasks(tasks);
        } catch (error) {
            console.error("Failed to fetch tasks:", error);
            // Handle error appropriately
        } finally {
            setLoading(false);
        }
    };

    const updateTasks = async () => {
        try {
            const tasks = await api.getSfnTasksBySlug(selectedSite.slug);
            setTasks(tasks);
        } catch (error) {
            console.error("Failed to update tasks:", error);
            // Handle error appropriately
        }
    };

    const handleOpenExecution = (url: string) => {
        window.open(url, '_blank');
    };


    useEffect(() => {
        fetchTasks();

        // Set up the interval for periodic fetches
        const intervalId = setInterval(updateTasks, 5000);

        // Cleanup function to clear the interval when the component unmounts
        // or if the selectedSite changes
        return () => clearInterval(intervalId);
    }, [selectedSite.slug, api]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <TableContainer component={Paper} sx={{ m: 0, p: 0}}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }} >Action</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">Status</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">Start At</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">End At</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="right">SFn Execution</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks.map((task) => (
                        <TableRow key={task.executionUrl} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {task.action}
                            </TableCell>
                            <TableCell align="right">
                                <StatusIndicator status={task.status} />
                                {/*<Typography>{task.status}</Typography>*/}
                            </TableCell>
                            <TableCell align="right">
                                <Typography>{formatLocalDateTime(task.startAt)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography>{formatLocalDateTime(task.endAt)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Button
                                    variant="outlined"
                                    startIcon={<OpenInNewIcon />}
                                    onClick={() => handleOpenExecution(task.executionUrl)}
                                >AWS</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EventsTabContent;
