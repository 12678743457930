import React, {useEffect, useState} from "react";

import {
    Box,
    Typography
} from "@mui/material";

import AirtableWebhookStatusIndicator from "./statusIndicators/WebhookStatusIndicator";


interface AirtableWebhookTabContentProps {
    airtableAppId: string;
    slug: string;
}



const AirtableWebhookTabContent: React.FC<AirtableWebhookTabContentProps> = ({ airtableAppId, slug}) => {
    useEffect(() => {
        console.log(`AirtableWebhookTabContent useEffect(). airtableAppId: ${airtableAppId}`);
    }, [airtableAppId]);

    if (!airtableAppId) {
        return <Box component="span">Base ID not available</Box>;
    }

    return (
        <Box display='flex' sx={{  marginBottom: 2 }}>
            <Typography variant="body1">Webhook status:</Typography>
            <AirtableWebhookStatusIndicator airtableAppId={airtableAppId} slug={slug} />
        </Box>
    );
}


export default AirtableWebhookTabContent;