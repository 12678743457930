import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './new-dashboard/App';
import theme from './new-dashboard/theme';
import { APIProvider } from "./new-dashboard/services/APIContext";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <APIProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="/:slug" element={<App />} />
                </Routes>
            </BrowserRouter>
        </APIProvider>
    </ThemeProvider>
);
