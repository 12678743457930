// SiteListWrapper.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {Box, TextField, Checkbox, Button, Typography, CircularProgress, Tooltip, Stack} from '@mui/material';
import SiteList from './SiteList';
import { Site } from '@irvin-group/api-data-types'; // Ensure this import path is correct
import { useAPI } from '../services/APIContext';
import ActionButton from './ActionButton';
import Chip from "@mui/material/Chip";
import {siteHasIssues, somethingInProgress} from "../App";
import WebhookStatusIndicator from "./statusIndicators/WebhookStatusIndicator"; // Assuming you have this from the previous setup

interface SiteListWrapperProps {
    sites: Site[];
    onSelectSite: (site: Site) => void;
    selectedSites: string[];
    onToggleSiteSelection: (selectedSlugs: string[]) => void;
    currentlySelectedSite: Site | null;
    sitesLoading: boolean;
}

const SiteListWrapper: React.FC<SiteListWrapperProps> = ({ sites, onSelectSite, selectedSites, onToggleSiteSelection, currentlySelectedSite, sitesLoading }) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { api } = useAPI(); // Use the API context if needed for actions

    const getFilteredSites = () => {
        if (searchTerm == '! Attention') {
            return sites.filter(site => siteHasIssues(site));
        }

        if (searchTerm == '! In Progress') {
            return sites.filter(site => somethingInProgress(site));
        }

        if (searchTerm) {
            return sites.filter(site => site.slug.toLowerCase().includes(searchTerm));
        }
        return sites;
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            onToggleSiteSelection(getFilteredSites().map(site => site.slug));
        } else {
            onToggleSiteSelection([]);
        }
    };

    const handleBuildAll = async () => {
        if (selectedSites.length > 0) {
            await api.buildSelectedSites(selectedSites)
        } else {
            await api.buildAll()
        }

    };

    const handlePublishAll = async () => {
        if (selectedSites.length > 0) {
            await api.publishSelectedSites(selectedSites)
        } else {
            await api.publishAll()
        }

    };

    const handleCheckDraftSiteAll = async () => {
        if (selectedSites.length > 0) {
            await api.checkDraftSiteSelected(selectedSites)
        } else {
            await api.checkDraftSiteAll()
        }

    };

    const filterSitesRequireAttention = () => {
        setSearchTerm('! Attention');
    };

    const filterSitesInProgress = () => {
        setSearchTerm('! In Progress');
    };

    const handleCleanAttention = () => {
        setSearchTerm('');
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', border: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, borderBottom: 1, borderColor: 'divider', backgroundColor: 'grey.200', zIndex: 1 }}>
                <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Sites
                    <Box component="span" sx={{ color: 'grey.600', fontWeight: 'normal' }}>({getFilteredSites().length})</Box>
                    <WebhookStatusIndicator airtableAppId={'appTqlfu0jSjvfhJf'} slug={'masterBaseSystemSlug'}></WebhookStatusIndicator>
                    <Stack direction="row" spacing={1}>
                        {getFilteredSites().filter(site => siteHasIssues(site)).length > 0 && (
                            <Chip
                                label={`${getFilteredSites().filter(site => siteHasIssues(site)).length} sites require attention`}
                                color="warning"
                                variant="outlined"
                                onDelete={searchTerm == '! Attention' ? handleCleanAttention : undefined}
                                onClick={filterSitesRequireAttention}
                            />
                        )}
                        {getFilteredSites().filter(site => somethingInProgress(site)).length > 0 && (
                            <Chip
                                label={`${getFilteredSites().filter(site => somethingInProgress(site)).length} sites in progress`}
                                color="secondary"
                                variant="outlined"
                                onDelete={searchTerm == '! In Progress' ? handleCleanAttention : undefined}
                                onClick={filterSitesInProgress}
                            />
                        )}
                    </Stack>
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', mb: 2 }}>
                    <ActionButton variant="contained" color="secondary"  sx={{ mr: 1 }} onClick={handleBuildAll}>
                        {selectedSites.length > 0 ? `🔨 Build ${selectedSites.length} Selected` : '🔨 Build All'}
                    </ActionButton>
                    <ActionButton variant="contained" color="info"  sx={{ mr: 1 }} onClick={handlePublishAll}>
                        {selectedSites.length > 0 ? `🚀 Publish ${selectedSites.length} Selected` : '🚀 Publish All'}
                    </ActionButton>
                    <ActionButton variant="contained" color="warning" onClick={handleCheckDraftSiteAll}>
                        {selectedSites.length > 0 ? `🔎 Check ${selectedSites.length} Selected Drafts` : '🔎 Check All Drafts'}
                    </ActionButton>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Tooltip title="Select all" placement="top">
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            sx={{ padding: '0 12px 0 0',  mr: 2 }}
                        />
                    </Tooltip>
                    <TextField
                        size="small"
                        placeholder="Search sites..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ flex: 1 }}
                    />
                </Box>

            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {sitesLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (

                    <SiteList
                        sites={getFilteredSites()}
                        onSelectSite={onSelectSite}
                        selectedSites={selectedSites}
                        onToggleSiteSelection={onToggleSiteSelection}
                        currentlySelectedSite={currentlySelectedSite}
                    />
                )}
            </Box>
        </Box>
    );
};

export default SiteListWrapper;
