import {Site} from "@irvin-group/api-data-types";
import React from "react";
import {Box, Typography} from "@mui/material";
import SiteActionStatusIndicator from "./statusIndicators/SiteActionStatusIndicator";
import AirtableWebhookStatusIndicator from "./statusIndicators/WebhookStatusIndicator";

interface SiteInfoTabContentProps {
    selectedSite: Site;
}

const SiteInfoTabContent: React.FC<SiteInfoTabContentProps> = ({ selectedSite }) => {
    return (
        <Box>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">Last Published: {selectedSite.publishDate ? new Date(selectedSite.publishDate).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Publishing Status: <SiteActionStatusIndicator status={selectedSite.publishStatus}></SiteActionStatusIndicator></Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">Last Build: {selectedSite.buildDate ? new Date(selectedSite.buildDate).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Build Status: <SiteActionStatusIndicator status={selectedSite.buildStatus}></SiteActionStatusIndicator></Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">Last Draft Check: {selectedSite.draftCheckDate ? new Date(selectedSite.draftCheckDate).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Draft Check Status: <SiteActionStatusIndicator status={selectedSite.draftCheckStatus}></SiteActionStatusIndicator></Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">Last Prod Check: {selectedSite.productionCheckDate ? new Date(selectedSite.productionCheckDate).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Prod Check Status: <SiteActionStatusIndicator status={selectedSite.productionCheckStatus}></SiteActionStatusIndicator></Typography>
            </Box>

            {/*<Box sx={{ marginBottom: 2 }}>*/}
            {/*    <Typography variant="body1">Last Source: {selectedSite.sourceDate ? new Date(selectedSite.sourceDate).toLocaleString() : 'N/A'}</Typography>*/}
            {/*    <Typography variant="body1">Source Status: <SiteActionStatusIndicator status={selectedSite.sourceStatus}></SiteActionStatusIndicator></Typography>*/}
            {/*</Box>*/}

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">Last Cloudfront Cache Invalidation: {selectedSite.invalidationDate ? new Date(selectedSite.invalidationDate).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Cloudfront Cache Invalidation Status: <SiteActionStatusIndicator status={selectedSite.invalidationStatus}></SiteActionStatusIndicator></Typography>
            </Box>






        </Box>
    );
}

export default SiteInfoTabContent;