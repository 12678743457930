import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningIcon from '@mui/icons-material/Warning';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PageStatus } from "@irvin-group/api-data-types";


interface StatusIndicatorProps {
    pageStatus: PageStatus;
}


const PageCheckStatusIndicator: React.FC<StatusIndicatorProps> = ({ pageStatus }) => {
    const renderIconAndColor = () => {
        if (pageStatus.statusCode != 200) {
            return { icon: <ErrorIcon />, color: 'red', text: pageStatus.statusCode };
        }
        return { icon: <CheckCircleIcon />, color: 'green', text: pageStatus.statusCode };
    };

    const { icon, color, text } = renderIconAndColor();

    return (
        <Tooltip title={pageStatus.error} placement="top-start">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: color }}>

                {icon}
                <Typography variant="body1">{text}</Typography>
            </Box>
        </Tooltip>
    );
};

export default PageCheckStatusIndicator;
