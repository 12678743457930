import {GetSfnTasksAPIResponse, GetSitesAPIResponse, ListSiteCheckResultsAPIResponse} from '@irvin-group/api-data-types';
import { sortBy } from 'lodash';
import {ListWebhooksResponse} from "@irvin-group/airtable-data-types";
import {WebhookItem} from "../../../src/lib/repositories/webhook.repository";

export default class DashboardAPI
{
  constructor(
    public readonly url: string) { }

  async getSites()
  {
    const resp: GetSitesAPIResponse = await this._get('sites');

    return sortBy(resp.sites, s => s.slug);
  }

  async getSfnTasks()
  {
    const resp: GetSfnTasksAPIResponse = await this._get('sfn-tasks');

    return resp.tasks
  }

  async getSfnTasksBySlug(slug: string){
    const resp: GetSfnTasksAPIResponse = await this._post('sites/sfn-tasks', { slug });

    return resp.tasks
  }

  async listWebhook(slug: string):  Promise<WebhookItem> {
    const resp: WebhookItem = await this._get(`airtable/listWebhook/?slug=${slug}`);
    return resp;
  }

  async setWebhook(slug: string) {
    return this._post(`airtable/setWebhook`, { slug });
  }

  async createNewLiveSiteChangeSet()
  {
    return this._post('actions/createNewLiveSiteChangeSet');
  }

  async createNewSingleSiteChangeSet(slug: string)
  {
    return this._post('actions/createSingleSiteChangeSet', { slug });
  }

  async buildSite(slug: string)
  {
    return this._post(`actions/build`, { slug });
  }

  async buildAll()
  {
    return this._post('actions/buildAll');
  }

  async buildSelectedSites(sites: string[])
  {
    return this._post('actions/buildSelectedSites', {
      sites
    });
  }

  async provision()
  {

  }

  async checkDraftSite(slug: string)
  {
    return this._post(`actions/checkDraft`, { slug });
  }

  async checkProductionSite(slug: string)
  {
    return this._post(`actions/checkProduction`, { slug });
  }

  async checkDraftSiteAll()
  {
    return this._post(`actions/checkDraftAll`);
  }

  async checkDraftSiteSelected(sites: string[])
  {
    return this._post(`actions/checkDraftSelected`,{
      sites
    });
  }

  async listSiteCheckResults(slug: string, environment: 'draft' | 'prod'){
    const resp: ListSiteCheckResultsAPIResponse = await this._post('sites/checkResults', { slug, environment });
    return resp.results
  }

  async publishSite(slug: string)
  {
    return this._post(`actions/promote`, { slug });
  }

  async publishAll()
  {
    return this._post('actions/promoteAll');
  }

  async publishSelectedSites(sites: string[])
  {
    return this._post('actions/promoteSelected', {
      sites
    });
  }


  async cleanAllStatuses() {
    return this._get('statuses/clean')
  }

  async _post(url: string, body: object = { })
  {
    const uri = `${this.url}/${url}`;
    const resp = await fetch(uri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });

    if (resp.status !== 200) {
      throw new Error(`Bad response code: ${resp.status}`);
    }

    return await resp.json();
  }

  async _get(url: string)
  {
    const uri = `${this.url}/${url}`;
    const resp = await fetch(uri);

    if (resp.status !== 200) {
      throw new Error(`Bad response code: ${resp.status}`);
    }

    return await resp.json();
  }
}