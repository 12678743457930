import {CheckPagesResult, Site} from "@irvin-group/api-data-types";
import React, {useEffect, useState} from "react";
import {useAPI} from "../services/APIContext";
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PageCheckStatusIndicator from "./statusIndicators/PageCheckStatusInficator";

interface EventsTabContentProps {
    selectedSite: Site;
    environment: 'prod' | 'draft'
}

const CrawlerResultsTabContent: React.FC<EventsTabContentProps> = ({ selectedSite, environment }) => {
    const {api} = useAPI();
    const [results, setResults] = useState<CheckPagesResult[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const fetchResults = async () => {
        setLoading(true);
        try {
            const results = await api.listSiteCheckResults(selectedSite.slug, environment);
            setResults(results);
        } catch (error) {
            console.error("Failed to fetch results:", error);
            // Handle error appropriately
        } finally {
            setLoading(false);
        }
    };

    const handleOpenExecution = (url: string) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        fetchResults();
    }, [selectedSite.slug, api]);


    if (loading) {
        return <CircularProgress />;
    }

    if (results.length < 1) {
        return (<Typography>No check found for {environment}</Typography>);
    }

    return (<TableContainer component={Paper} sx={{ m: 0, p: 0, border: 'none'}}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">Status</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Url</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {results[0].pageStatuses.map((pageStatus) => (
                    <TableRow key={pageStatus.url} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                            <PageCheckStatusIndicator pageStatus={pageStatus}></PageCheckStatusIndicator>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Button
                                variant="text"
                                startIcon={<OpenInNewIcon />}
                                onClick={() => handleOpenExecution(pageStatus.url)}
                            >{pageStatus.url}</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>);
}

export default CrawlerResultsTabContent;