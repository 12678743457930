import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material/Button';

// Extend ButtonProps to enforce onClick to be a function returning a promise
interface ActionButtonProps extends Omit<ButtonProps, 'onClick'> {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsLoading(true);
        try {
            await onClick(event);
        } catch (error) {
            console.error("Error executing onClick:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <LoadingButton
            {...props}
            loading={isLoading}
            onClick={handleClick}
        >
            {props.children}
        </LoadingButton>
    );
};

export default ActionButton;
