import React, {useState, useEffect } from 'react';
import { useAPI } from './services/APIContext';
import {Container, CircularProgress, Typography, Box, Link, Grid, Button} from '@mui/material';
import SiteListWrapper from './components/SiteListWrapper';
import SiteDetails from './components/SiteDetails';
import { Site } from '@irvin-group/api-data-types';
import { useParams, useNavigate } from 'react-router-dom';

export function siteHasIssues(site: Site): boolean {
    return site.buildStatus == 'FAILURE'
        || site.draftCheckStatus == 'FAILURE'
        || site.publishStatus == 'FAILURE'
        || site.invalidationStatus == 'FAILURE'
        || site.productionCheckStatus == 'FAILURE';
}

export function somethingInProgress(site: Site): boolean {
    if (site.slug == 'app-reactors') {
        return false
    }
    return false;
}


export default function App() {
    const [selectedSiteSlug, setSelectedSiteSlug] = useState<string | null>(null);
    const [selectedSites, setSelectedSites] = useState<string[]>([]); // Tracks selected sites by slug
    const [sites, setSites] = useState<Site[]>([]);
    const [sitesLoading, setSitesLoading] = useState<boolean>(true);

    const { slug } = useParams<{ slug: string }>();
    const navigate = useNavigate();
    const { api } = useAPI();


    const fetchSites = async () => {
        try {
            setSitesLoading(true);
            const loadedSites = await api.getSites();
            setSites(loadedSites);
            setSitesLoading(false);

            // If there's a slug in the URL, attempt to select the corresponding site
            if (slug) {
                const siteExists = loadedSites.some(site => site.slug === slug);
                if (siteExists) {
                    setSelectedSiteSlug(slug);
                } else {
                    // If no site matches the slug, handle accordingly
                    navigate('/'); // Navigate to the base path if the slug is invalid
                }
            }
        } catch (error) {
            console.error("Error fetching sites:", error);
            setSitesLoading(false);
        }
    };

    const updateSites = async () => {
        try {
            const loadedSites = await api.getSites();
            setSites(loadedSites);
        } catch (error) {
            console.error("Error fetching sites:", error);
        }
    };

    useEffect(() => {
        fetchSites();

        // Set up the interval to call fetchSites every 5 seconds
        const intervalId = setInterval(updateSites, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    const handleSelectSite = (site: Site) => {
        setSelectedSiteSlug(site.slug); // Update the stored slug when a site is selected
        navigate(`/${site.slug}`); // Update the URL
    };

    const handleToggleSiteSelection = (slugs: string[]) => {
        setSelectedSites(slugs);
    };

    const getSelectedSite = (): Site | null => {
        // If no site is selected, return null early
        if (!selectedSiteSlug) return null;

        // Find the site in the sites array that matches the selectedSiteSlug
        const site = sites.find(site => site.slug === selectedSiteSlug);

        if (!site) return null;

        return site;
    };


    return (
        <Container maxWidth="xl">
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} sx={{m: 2}}>
                    <Grid item xs={5}>
                        <SiteListWrapper
                            sites={sites} // Your state holding the list of sites
                            onSelectSite={handleSelectSite}
                            selectedSites={selectedSites}
                            onToggleSiteSelection={handleToggleSiteSelection}
                            currentlySelectedSite={getSelectedSite()}
                            sitesLoading={sitesLoading}/>
                    </Grid>
                    <Grid item xs={7}>
                        {getSelectedSite() ? (
                            <SiteDetails selectedSite={getSelectedSite()!} />
                        ) : (
                            <Typography>Select a site to view details</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );

}