import React from 'react';
import { Site } from '@irvin-group/api-data-types';
import {Box, Typography, Link, Checkbox, List, ListItem, ListItemText, Divider, CircularProgress} from '@mui/material';
import CopyButton from "../../react/CopyButton";
import {siteHasIssues, somethingInProgress} from "../App";

interface SiteListProps {
    sites: Site[];
    onSelectSite: (site: Site) => void;
    selectedSites: string[]; // Comes from parent component
    onToggleSiteSelection: (slugs: string[]) => void; // Function to update selection in parent
    currentlySelectedSite: Site | null;
}

const SiteList: React.FC<SiteListProps> = ({ sites, onSelectSite, selectedSites, onToggleSiteSelection, currentlySelectedSite }) => {
    const handleToggleSite = (slug: string, event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent card click event from firing
        const selectedIndex = selectedSites.indexOf(slug);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedSites, slug];
        } else {
            newSelected = selectedSites.filter(s => s !== slug);
        }

        onToggleSiteSelection(newSelected); // Update parent state
    };

    const style = {
        p: 2,
        width: '100%',
        backgroundColor: 'background.paper',
    };

    return (
        <Box sx={{ width: '100%', maxHeight: '75vh'}}>
            <List sx={style}>
                {sites.map((site, index) => (
                    <React.Fragment key={site.slug}>
                        <ListItem
                            alignItems="center"
                            secondaryAction={somethingInProgress(site) ? <CircularProgress /> : siteHasIssues(site) ? <Typography>⚠️</Typography> : null}
                            sx={{
                                width: '100%',
                                cursor: 'pointer',
                                backgroundColor: currentlySelectedSite?.slug === site.slug ? '#bbdefb' : 'transparent', // Light blue background for selected
                                border: currentlySelectedSite?.slug === site.slug ? '1px solid #2196f3' : 'none',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                            }}
                            onClick={() => onSelectSite(site)}
                        >
                            <Checkbox
                                edge="start"
                                checked={selectedSites.includes(site.slug)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${site.slug}` }}
                                onChange={(event) => handleToggleSite(site.slug, event)}
                                onClick={(event) => event.stopPropagation()}
                                sx={{ padding: '0 12px 0 0' }} // Adjust padding for visual alignment, if necessary
                            />
                            <ListItemText
                                primary={<Box display="flex" alignItems="center" justifyContent="flex-start" gap="8px">
                                    <Typography variant="h5">{site.slug}</Typography>
                                    <CopyButton text={site.slug} />
                                </Box>}
                                secondary={


                                    <>
                                        <Link href={`https://${site.siteUrl}`} target="_blank" rel="noopener noreferrer" onClick={(event) => event.stopPropagation()}>
                                            {site.siteUrl}
                                        </Link>
                                        <br /><br />

                                        <Typography component="span" variant="body2" color="text.primary">
                                            Last Published: {site.publishDate ? new Date(site.publishDate).toLocaleString() : 'N/A'}
                                        </Typography>


                                    </>
                                }
                            />
                        </ListItem>
                        {index < sites.length - 1 && <Divider component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default SiteList;
