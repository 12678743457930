import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


interface SiteActionStatusIndicatorProps {
    status: string | undefined;
}


const SiteActionStatusIndicator: React.FC<SiteActionStatusIndicatorProps> = ({ status }) => {
    const renderIconAndColor = () => {
        switch (status) {
            case 'SUCCESS':
                return { icon: <CheckCircleIcon />, color: 'green', text: 'SUCCESS' };
            case 'FAILURE':
                return { icon: <ErrorIcon />, color: 'red', text: 'FAILURE' };
            default:
                return { icon: <HelpOutlineIcon />, color: 'gray', text: 'Unknown' };
        }
    };

    const { icon, color, text } = renderIconAndColor();

    return (
        <Box sx={{ display: 'inline', alignItems: 'center', gap: 2, color: color }}>
            {icon}
            {text}
        </Box>
    );
};

export default SiteActionStatusIndicator;
