import React, { createContext, useContext } from 'react';
import DashboardAPI from '../../lib/DashboardAPI';

// Define the type of the context
interface APIContextType {
    api: DashboardAPI;
}

// Create the context with an undefined default value
const APIContext = createContext<APIContextType | undefined>(undefined);

// Define a provider component
export const APIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const api = new DashboardAPI('https://api.static-sites.irvin-group-labs.com');

    return <APIContext.Provider value={{ api }}>{children}</APIContext.Provider>;
};

// Hook to use the API context
export const useAPI = (): APIContextType => {
    const context = useContext(APIContext);
    if (context === undefined) {
        throw new Error('useAPI must be used within an APIProvider');
    }
    return context;
};
